import {SIMPLE_STORAGE} from '@youtoken/ui.service-storage';
import {SENTRY} from '@youtoken/ui.sentry';
import {WidgetIncomingParams} from './WidgetIncomingParams';

const WIDGET_STORAGE_KEY = 'widget_incoming_params';

export const getIncomingParams = (): WidgetIncomingParams | null => {
  const params = SIMPLE_STORAGE.get(WIDGET_STORAGE_KEY);

  if (!params) {
    return null;
  }

  try {
    return JSON.parse(params);
  } catch (error: any) {
    SENTRY.capture(error, {
      source: 'getIncomingParams',
    });
    return null;
  }
};

export const setIncomingParams = (incomingParams: WidgetIncomingParams) => {
  try {
    const paramsString = JSON.stringify(incomingParams);
    SIMPLE_STORAGE.set(WIDGET_STORAGE_KEY, paramsString);
  } catch (error: any) {
    SENTRY.capture(error, {
      source: 'setIncomingParams',
    });
  }
};
