'use client';

import * as React from 'react';
import {observer} from 'mobx-react';
import {useRouter} from 'next/navigation';
import {GLOBAL} from '@youtoken/ui.service-global';
import {VerificationResource} from '@youtoken/ui.resource-verification';
import {ROUTES} from '@/constants/routes';
import {getAndStoreIncomingParams} from '@/helpers/incomingParams';

export default observer(function RootPage() {
  const {isAuthorized} = GLOBAL;
  const verificationResource = VerificationResource.use({withFallback: true});
  const {push} = useRouter();

  React.useLayoutEffect(() => {
    getAndStoreIncomingParams();
  }, []);

  // NOTE: here is effect with redirects, make all need stuff before
  React.useEffect(() => {
    if (isAuthorized) {
      if (verificationResource.accountVerificationComplete) {
        push(ROUTES.MAIN_FORM);
        return;
      }

      push(ROUTES.PLEASE_VERIFY);
    }

    push(ROUTES.WELCOME_FORM);
  }, []);

  return null;
});
