import {optional, primitive, serializable} from 'serializr';

export class WidgetIncomingParams {
  @serializable(primitive())
  toTicker!: string; // which cryptocurrency should the user’s deposit be converted into?

  @serializable(primitive())
  wallet!: string; // to which wallet should the crypto be sent upon successful deposit

  @serializable(primitive())
  network!: string; // network where the wallet is located

  @serializable(optional(primitive()))
  memoId?: string; // optional, required for coins like XRP

  @serializable(primitive())
  contractor!: string; // name of the counterparty whose clients use the widget
}
