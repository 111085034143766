import {TRANSPORT} from '@youtoken/ui.transport';
import {
  IncomingParams,
  setIncomingParams,
  WidgetIncomingParams,
} from '@/helpers/storage';
import {SENTRY} from '@youtoken/ui.sentry';
import {i18n} from '@youtoken/ui.service-i18n';
import {AxiosResponse} from 'axios';
import {deserialize} from 'serializr';

export const getAndStoreIncomingParams = () => {
  TRANSPORT.API.get('/v1/widget/on-ramp/overview')
    .then((res: AxiosResponse<IncomingParams>) => {
      setIncomingParams(deserialize(WidgetIncomingParams, res.data));
      i18n._changeLanguage(res.data.locale);
    })
    .catch(error => {
      SENTRY.capture(error, {source: 'getAndStoreIncomingParams'});
    });
};
